<template lang="html">
    <div>
      <div class="Actions">
        <h2>Bienvenido a la pantalla de testing de redirects de test</h2>
  
        <h3>Selecciona el redirect</h3>
        <button type="button" name="button" v-on:click="redirect(0)">QuotaFull</button>
        <button type="button" name="button" v-on:click="redirect(1)">Trap</button>
        <button type="button" name="button" v-on:click="redirect(2)">Quality Killer</button>
        <button type="button" name="button" v-on:click="redirect(3)">Questions Stage</button>
        <button type="button" name="button" v-on:click="redirect(4)">Task Ended</button>
      </div>
      <div class="">
        <p>Queries recibidos en la URL: <br> {{queries}}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'testingTest',
    data() {
      return{
        queries:"?"
      }
    },
  
    methods:{
      redirect(type){
        let url = ""
        let hostname = window.location.hostname
        if(window.location.hostname == "localhost") hostname = "localhost:8080"
        url = window.location.protocol + "//" + hostname + "/executionRedirect" + this.queries
        if(type == 0) url +="&completion=embeddedQuota"
        if(type == 1) url +="&completion=embeddedTrap" 
        if(type == 2) url +="&completion=qualityKiller" 
        if(type == 3) url +="&stage=questions" 
        if(type == 4) url +="&completion=taskEnded"
        window.location.href = url
      }
    },
    async mounted(){
      this.queries += `testId=${this.$route.params.id}`
      for(let key in this.$route.query){
        this.queries += `&${key}=${this.$route.query[key]}`
      }
      if (this.queries == "?") this.queries = ""
    }
  }
  </script>
  
  
  <style lang="scss" scoped>
  .Actions{
  
  }
  </style>
  